import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import styles from './ContentHeading.module.scss';

export interface ContentHeadingProps {
	additional?: React.ReactNode;
	className?: string;
	subtext?: string;
	heading?: React.ReactNode;
	headingLevel?: 'h1' | 'h2';
}

export const ContentHeading: React.FC<ContentHeadingProps> = ({ additional, className, subtext, heading, headingLevel = 'h1' }) => (
	<div className={classNames(styles.ContentHeading, className)}>
		{heading && (
			<div className={classNames(styles.ContentHeading_heading, !subtext ? styles.ContentHeading_heading___margin : '')}>
				<Heading headingLevel={headingLevel} style="xl">
					{heading}
				</Heading>
				{additional}
			</div>
		)}
		{subtext && <div className={styles.ContentHeading_subtext}>{subtext}</div>}
	</div>
);
