import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { ContentCard } from 'components/2-molecules/Card';
import { TeaserItem, TeaserItemProps } from './TeaserItem/TeaserItem';
import styles from './TeaserModule.module.scss';

export interface TeaserModuleProps {
	heading?: string;
	link?: React.ReactElement;
	noItemsMessage?: string;
	columns?: 2 | 3 | 4;
	teaserItems?: TeaserItemProps[];
	noTopMargin?: boolean;
	hideImagesOnMobile?: boolean;
	className?: string;
	aspectRatio: '1:1' | '16:9';
}

export const TeaserModule: React.FC<TeaserModuleProps> = ({
	heading,
	link,
	noItemsMessage,
	columns = 1,
	teaserItems,
	noTopMargin = false,
	hideImagesOnMobile = false,
	className,
	aspectRatio,
}) => {
	return (
		<div className={classNames(styles.TeaserModule, { [styles.TeaserModule___noTopMargin]: noTopMargin }, className)}>
			{(link || heading) && (
				<div className={styles.TeaserModule_top}>
					{heading && (
						<Heading className={styles.TeaserModule_heading} style={'md'} headingLevel={'h2'}>
							{heading}
						</Heading>
					)}
					{link && link}
				</div>
			)}

			{teaserItems && teaserItems.length > 0 ? (
				<div
					className={classNames(
						styles.TeaserModule_items,
						styles[`TeaserModule_items___${!teaserItems[0]?.imagePosition || teaserItems[0]?.imagePosition === 'Top' ? columns : 1}`],
					)}
				>
					{teaserItems.map((item, index) => (
						<TeaserItem
							{...item}
							hideImageOnMobile={hideImagesOnMobile}
							aspectRatio={aspectRatio}
							imageSize={
								columns === 1
									? '(max-width: 576px) 100vw, 50vw'
									: `(max-width: 639px) 100vw, (min-width: 639px) 50vw, (min-width:992px) ${100 / columns}vw`
							}
							key={index}
						/>
					))}
				</div>
			) : (
				noItemsMessage && <ContentCard text={noItemsMessage} />
			)}
		</div>
	);
};
