import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { Picture } from 'components/1-atoms/Media';
import { RichText, RichTextModal } from 'components/1-atoms/RichText';
import React from 'react';
import { ConditionalWrapper } from 'utilities/conditionalWrapper';
import { formatDate } from 'utilities/date';
import { sanitizeAspectRatio } from 'utilities/sanitizeAspectRatio';
import styles from './TeaserItem.module.scss';
export interface TeaserItemProps {
	additionalInfo?: React.ReactNode;
	className?: string;
	date?: string;
	picture?: UI.Image;
	video?: React.ReactNode;
	heading: string;
	text?: string;
	modals?: Array<RichTextModal>;
	link?: React.ReactElement;
	urlWrapperLink?: string;
	imagePosition?: 'Left' | 'Right' | 'Top';
	imageDisplayStyle?: UI.ImageDisplayStyle;
	imageIsPDF?: boolean;
	imageHasBorder?: boolean;
	hideImageOnMobile?: boolean;
	imageSize?: string;
	backgroundStyle?: UI.BackgroundStyle;
	style?: 'contained';
	aspectRatio?: '16:9' | '1:1';
}

export const TeaserItem = ({
	additionalInfo,
	className,
	date,
	picture,
	video,
	heading,
	text,
	modals,
	link,
	urlWrapperLink,
	imagePosition = 'Top',
	imageDisplayStyle = 'cover',
	imageIsPDF,
	imageHasBorder,
	imageSize = '100vw',
	hideImageOnMobile = false,
	backgroundStyle,
	style,
	aspectRatio,
}: TeaserItemProps) => {
	backgroundStyle = backgroundStyle || 'White';
	const aspectRatioSanitized = sanitizeAspectRatio({ aspectRatio, defaultRatio: '16:9' });

	const [width, height] = aspectRatioSanitized.split('-');
	const aspectRatioImg = Number(height) / Number(width);

	return (
		<div
			className={classNames(
				styles.TeaserItem,
				styles[`TeaserItem___${imagePosition}`],
				imageIsPDF ? styles.TeaserItem___PDF : '',
				styles[`TeaserItem___${backgroundStyle}`],
				styles[`TeaserItem___${style}`],
				backgroundStyle === 'ThemeWhite' ? 'u-bg-color--white' : `u-bg-color--${backgroundStyle.toLowerCase()}`,
				className,
			)}
		>
			{video && (
				<div
					className={classNames(styles.TeaserItem_media, !imageIsPDF ? styles[`TeaserItem_media___${aspectRatioSanitized}`] : '', {
						[styles.TeaserItem_media___hideOnMobile]: hideImageOnMobile,
					})}
				>
					<div className={styles.TeaserItem_video}>{video}</div>
				</div>
			)}
			<ConditionalWrapper
				condition={urlWrapperLink}
				wrapper={(children) => (
					<a className={styles.TeaserItem_link} href={urlWrapperLink}>
						{children}
					</a>
				)}
			>
				{picture && (
					<div
						className={classNames(styles.TeaserItem_media, !imageIsPDF ? styles[`TeaserItem_media___${aspectRatioSanitized}`] : '', {
							[styles.TeaserItem_media___hideOnMobile]: hideImageOnMobile,
						})}
					>
						<Picture
							className={classNames(styles.TeaserItem_picture)}
							{...picture}
							isFullHeight
							isPositionedAbsolute={true}
							sizes={imageSize}
							aspectRatio={imageIsPDF || imageDisplayStyle?.startsWith('contain') ? undefined : aspectRatioImg}
							isCover={!imageIsPDF && !imageDisplayStyle?.startsWith('contain')}
							padding={!imageDisplayStyle.includes('Padding') ? 'none' : imageDisplayStyle.includes('Small') ? 'small' : 'large'}
							border={imageHasBorder}
						/>
					</div>
				)}
				<div className={styles.TeaserItem_content}>
					{date && <p className={styles.TeaserItem_date}>{formatDate(date, 'dd.mm.yyyy')}</p>}
					<Heading
						className={classNames(styles.TeaserItem_heading, urlWrapperLink ? styles.TeaserItem_heading___isLink : '')}
						style={'sm'}
						headingLevel={'h2'}
					>
						{heading}
					</Heading>
					{additionalInfo}
					{text && (
						<RichText
							className={classNames(styles.TeaserItem_text, link && !urlWrapperLink && styles.TeaserItem_text___noBottomMargin)}
							content={text}
							modals={modals}
						/>
					)}
					{link && !urlWrapperLink && <div className={styles.TeaserItem_contentLink}>{link}</div>}
				</div>
			</ConditionalWrapper>
		</div>
	);
};
