import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { RichText } from 'components/1-atoms/RichText';
import styles from './SearchCard.module.scss';

export interface SearchCardProps {
	className?: string;
	heading?: string;
	teaser?: string;
	searchInput: React.ReactNode;
	text?: string;
	link?: React.ReactElement;
}

export const SearchCard: React.FC<SearchCardProps> = ({ className, heading, teaser, searchInput, text, link }) => {
	return (
		<div className={classNames(styles.SearchCard, className)}>
			{heading && (
				<Heading className={styles.SearchCard_heading} headingLevel="h1" style="xl">
					{heading}
				</Heading>
			)}
			{teaser && (
				<Heading className={styles.SearchCard_teaser} headingLevel="h2" style="sm">
					{teaser}
				</Heading>
			)}
			{searchInput}
			{text && <RichText className={styles.SearchCard_text} content={text} />}
			{link}
		</div>
	);
};
