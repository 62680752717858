import classNames from 'classnames';
import styles from './ResponseCard.module.scss';

export interface ResponseCardProps {
	className?: string;
	message: string;
	style?: 'default' | 'success' | 'error';
	children?: React.ReactNode;
}

export const ResponseCard = ({ className, message, style = 'default', children }: ResponseCardProps) => (
	<div className={classNames(styles.ResponseCard, className)}>
		<div className={classNames(styles.ResponseCard_box, styles[`ResponseCard_box___${style}`])}>
			<p className={styles.ResponseCard_message}>{message}</p>
			{children}
		</div>
	</div>
);
