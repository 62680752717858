import classNames from 'classnames';
import { Picture } from 'components/1-atoms/Media';
import { ContentCard } from '../ContentCard';
import styles from './HeroCard.module.scss';

export interface HeroCardProps {
	className?: string;
	image: UI.Image;
	imageAspectRatio?: '16:3' | '16:6' | '16:9';
	fullWidthImage?: boolean;
	hideImageOnMobile?: boolean;
	noTopMargin?: boolean;
	content?: {
		heading: string;
		headingSubtext?: string;
		text?: string;
		link?: React.ReactElement;
	};
}

export const HeroCard: React.FC<HeroCardProps> = ({
	className,
	image,
	imageAspectRatio = '16:6',
	fullWidthImage,
	hideImageOnMobile,
	noTopMargin,
	content,
}) => {
	const [width, height] = imageAspectRatio.split(':');
	const aspectRatio = Number(height) / Number(width);

	return (
		<div
			className={classNames(
				styles.HeroCard,
				{ [styles.HeroCard___noTopMargin]: noTopMargin, [styles.HeroCard___smallImage]: !fullWidthImage },
				styles[`HeroCard___${imageAspectRatio.replace(':', 'x')}`],
				className,
			)}
		>
			<div className={styles.HeroCard_media} aria-hidden={true}>
				<span className={hideImageOnMobile ? styles.HeroCard___hideOnMobile : ''}>
					<Picture
						assetClassName={styles.HeroCard_mediaAsset}
						aspectRatio={aspectRatio}
						url={image?.url}
						altText={image?.altText}
						focalPoint={image?.focalPoint}
						sizes="100vw"
					/>
				</span>
			</div>
			{content && (
				<div className={styles.HeroCard_content}>
					<ContentCard heading={content.heading} headingSubtext={content.headingSubtext} text={content.text} link={content.link} />
				</div>
			)}
		</div>
	);
};
