import { Video } from 'designsystem/components/1-atoms/Media';
import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
declare global {
	interface Window {
		KalturaPlayer: {
			setup: ({ targetId, provider }) => { loadMedia: ({ entryId }) => void };
			getPlayer: (targetId: string) => unknown;
		};
	}
}

export const VideoFeature: React.FC<Content.Video> = ({ content, settings, noBackground = false }) => {
	const { url, title, entryId } = content?.properties ?? {};
	const { noTopMargin, aspectRatio } = settings?.properties ?? {};

	const kalturaPartnerId = 10209;
	const uiconfId = 23452349;
	const targetId = entryId ? entryId + '-target' : null;

	useEffect(() => {
		if (!entryId) return;
		try {
			window.KalturaPlayer &&
				window.KalturaPlayer.setup({
					targetId: targetId,
					provider: {
						partnerId: kalturaPartnerId,
						uiConfId: uiconfId,
					},
				}).loadMedia({ entryId: entryId });
		} catch (e) {
			console.error(e.message);
		}
	}, [content, targetId, entryId]);

	return (
		<>
			<Head>
				<meta property="og:video" content={url} />
			</Head>

			<Script
				src={`https://api.frp2.ovp.kaltura.com/p/${kalturaPartnerId}/embedPlaykitJs/uiconf_id/${uiconfId}?autoembed=true&targetId=${targetId}&entry_id=${entryId}&config[playback]={"autoplay":false}`}
			/>
			<BackgroundContainerFeature noTopMargin={noTopMargin} enableBackground={!noBackground}>
				<>
					<Video embedId={targetId} url={!entryId && url} title={title} aspectRatio={!aspectRatio ? undefined : aspectRatio} />
				</>
			</BackgroundContainerFeature>
		</>
	);
};
