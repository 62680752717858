import classNames from 'classnames';
import { Picture } from 'components/1-atoms/Media';
import { customizeElement } from 'utilities/customizeElement';
import styles from './ShortcutCard.module.scss';

export interface ShortcutCardProps {
	className?: string;
	image: UI.Image & {
		sizes?: string;
	};
	linkText: string;
	link: React.ReactElement;
	imageDisplayStyle?: UI.ImageDisplayStyle;
}

export const ShortcutCard: React.FC<ShortcutCardProps> = ({ className, image, link, linkText, imageDisplayStyle = 'cover' }) => {
	return customizeElement({
		element: link,
		style: classNames(styles.ShortcutCard, className),
		children: (
			<>
				<div className={styles.ShortcutCard_media}>
					<Picture
						url={image.url}
						altText={image.altText}
						aspectRatio={imageDisplayStyle === 'cover' ? 9 / 16 : undefined}
						padding={!imageDisplayStyle.includes('Padding') ? 'none' : imageDisplayStyle.includes('Small') ? 'small' : 'large'}
						focalPoint={image.focalPoint}
						isFullHeight
						isCover={imageDisplayStyle === 'cover'}
						sizes={image.sizes}
					/>
				</div>
				<div className={styles.ShortcutCard_textbox}>{linkText}</div>
			</>
		),
	});
};
