import { BackgroundContainer } from 'designsystem/components/1-atoms/BackgroundContainer';
import React from 'react';

export interface BackgroundContainerProps {
	enableBackground: boolean;
	noTopMargin?: boolean;
	children: React.ReactElement;
}

export const BackgroundContainerFeature: React.FC<BackgroundContainerProps> = ({ enableBackground, noTopMargin, children }): React.ReactElement =>
	enableBackground ? <BackgroundContainer noTopMargin={noTopMargin}>{children}</BackgroundContainer> : children;
