import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { Icon } from 'components/1-atoms/Media';
import styles from './SpecsCard.module.scss';

export interface SpecsCardProps {
	className?: string;
	heading: string;
	icon?: React.ReactNode;
	mainContent?: React.ReactNode;
	secondaryContent?: React.ReactNode;
}

export const SpecsCard: React.FC<SpecsCardProps> = ({ className, heading, icon, mainContent, secondaryContent }) => (
	<section className={classNames(styles.SpecsCard, className)}>
		<div className={styles.SpecsCard_headingWrapper}>
			{icon && (
				<Icon className={styles.SpecsCard_icon} ariaHidden={true}>
					{icon}
				</Icon>
			)}
			<Heading headingLevel="h3">{heading}</Heading>
		</div>

		<div className={styles.SpecsCard_container}>
			{mainContent && <div className={styles.SpecsCard_wrapper}>{mainContent}</div>}
			{secondaryContent && <div className={styles.SpecsCard_wrapper}>{secondaryContent}</div>}
		</div>
	</section>
);
