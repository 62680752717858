import classNames from 'classnames';
import { RichText } from 'components/1-atoms/RichText';
import { ContentHeading } from 'components/2-molecules/ContentHeading';
import styles from './ContentCard.module.scss';

export type ContentCardLinkType = 'cta' | 'arrow';

export interface ContentCardProps {
	additional?: React.ReactNode;
	className?: string;
	heading?: string;
	headingLevel?: 'h1' | 'h2';
	headingSubtext?: string;
	text?: string;
	richText?: React.ReactNode;
	link?: React.ReactElement;
}

export const ContentCard: React.FC<ContentCardProps> = ({ additional, className, heading, headingLevel, headingSubtext, richText, text, link }) => {
	return (
		<div className={classNames(styles.ContentCard, className)}>
			<ContentHeading additional={additional} heading={heading} headingLevel={headingLevel} subtext={headingSubtext} />
			{text && <RichText content={text} />}
			{richText}
			{link && <div className={styles.ContentCard_link}>{link}</div>}
		</div>
	);
};
