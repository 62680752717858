import classNames from 'classnames';
import { Heading, HeadingLevel } from 'components/1-atoms/Heading';
import styles from './CalculationCard.module.scss';

type Calculation = {
	name: string;
	amount: string;
	label: string;
};
export interface CalculationCardProps {
	className?: string;
	header?: string;
	headingLevel?: HeadingLevel;
	calculations: Calculation[];
	result: Omit<Calculation, 'label'>;
}

export const CalculationCard: React.FC<CalculationCardProps> = ({ className, header, headingLevel = 'h3', calculations, result }) => {
	return (
		<div className={classNames(className, styles.CalculationCard)}>
			<Heading className={styles.CalculationCard_header} headingLevel={headingLevel}>
				{header}
			</Heading>

			{calculations?.map(({ name, label, amount }, index) => {
				return (
					<div className={styles.CalculationCard_wrapper} key={`${name}${index}`}>
						<p className={styles.CalculationCard_text}>{name}</p>
						<div className={styles.CalculationCard_calculation}>
							<p className={styles.CalculationCard_text}>{label}</p>
							<p className={styles.CalculationCard_text}>{amount}</p>
						</div>
					</div>
				);
			})}

			<div className={classNames(styles.CalculationCard_calculation, styles.CalculationCard_result)}>
				<p className={styles.CalculationCard_text}>{result?.name}</p>
				<p>{result?.amount}</p>
			</div>
		</div>
	);
};
