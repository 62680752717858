import classNames from 'classnames';
import { Button } from 'components/1-atoms/Buttons';
import styles from './LoginCard.module.scss';

export interface LoginCardProps {
	className?: string;
	heading: React.ReactNode;
	buttonText?: string;
	errorMessage?: string;
	signInMethod: () => void;
}

export const LoginCard: React.FC<LoginCardProps> = ({ className, heading, buttonText = 'Login', errorMessage, signInMethod }) => {
	return (
		<section className={classNames(styles.LoginCard, className)}>
			{heading}
			<div className={styles.LoginCard_wrapper}>
				<Button className={classNames(styles.LoginCard_button, styles.LoginCard_button___primary)} onClick={signInMethod}>
					{buttonText}
				</Button>
				{errorMessage && <div className={styles.LoginCard_error}>{errorMessage}</div>}
			</div>
		</section>
	);
};
