import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { RichText } from 'components/1-atoms/RichText';
import styles from './ErrorCard.module.scss';

export interface ErrorCardProps {
	className?: string;
	header: string;
	description: string;
}

export const ErrorCard: React.FC<ErrorCardProps> = ({ className, header, description }) => (
	<section className={classNames(styles.ErrorCard, className)}>
		<div className={styles.ErrorCard_wrapper}>
			<Heading className={styles.ErrorCard_header} headingLevel="h2" style="lg">
				{header}
			</Heading>
			<RichText content={description} />
		</div>
	</section>
);
